// this file will be deleted after the migration
import React from 'react';
import { SignupCaptcha } from '../components/hybrid/SignupCaptcha';

export const LandingPageContainerHybrid = (): JSX.Element => {
  return (
    <div>
      <SignupCaptcha />
    </div>
  );
};

export default LandingPageContainerHybrid;
